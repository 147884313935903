import { useCallback, KeyboardEvent, useEffect, useState } from "react";
import { ReplyTypeProps } from "./data";
import "./replyType.scss";
import ScoreCard from "./ScoreCard";

interface CustomReplyProps extends ReplyTypeProps {
  relatedStage?: string;
}

const CustomReply: React.FC<CustomReplyProps> = ({
  className,
  label,
  onSubmit,
  onChange,
  value,
  stage,
  relatedStage,
}) => {
  const [textMessage, setTextMessage] = useState<string>("");
  console.log("stage custom", stage);

  const [scoreData, setScoreData] = useState(null);

  const onEnter = useCallback(
    (event?: KeyboardEvent<HTMLButtonElement>) => {
      if (event?.key === "Enter") {
        onSubmit();
      }
    },
    [onSubmit]
  );

  return (
    <div
      className={`form-group description ${className ? " " + className : ""}`}
    >
      <label>{textMessage}</label>
      <div dangerouslySetInnerHTML={{ __html: label }} />
      <div className="form-control">
        <button
          className="btn btn-rounded submit"
          onClick={onSubmit}
          onKeyDown={onEnter}
        >
          View Model Answer
        </button>
      </div>
    </div>
  );
};

export default CustomReply;
