import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Play from "../../icons/Play";
import Pause from "../../icons/Pause";
import { Journey } from "../data";
import Prev from "../../icons/Prev";
import HomeIcon from "../../icons/HomeIcon";
import VideoReply from "../ReplyType/VideoReply";
import Unmute from "../../icons/UnmuteIcon";
import ReloadIcon from "../../icons/ReloadIcon";
import { JourneyReplyType } from "../JourneyReplyType";
import "./journeyPlayer.scss";
import PopUp from "../../core/PopUp/PopUp";
import { ConfirmPopUp } from "../ConfirmPopUp";
import Dropdown from "../Dropdown/Dropdown";
import { getNum } from "../../shared/utils";
import { ProjectDataContext } from "../ChatBox";

interface JourneyPlayerProps {
  stageData: any;
  allStageData: any;
  className?: string;
  journeyData: Journey | null;
  onAudioEnd: (audioText?: string) => void;
  handleNextStage: (stage?: string, tagData?: any) => void;
  handlePrevStage: (stage?: string) => void;
  isPrevVisible: boolean;
  thumbnail?: string;
  backToHome?: (action: "yes" | "no") => void;
  replyAnswer: string;
  setReplyAnswer: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  showPopUp: boolean;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  textHeader?: string;
  ModelType?: string;
  isModel?: string;
  brandName?: string;
  isQuizJourney?: boolean;
}

const JourneyPlayer: React.FC<JourneyPlayerProps> = ({
  stageData,
  allStageData,
  className,
  journeyData,
  onAudioEnd,
  handleNextStage,
  handlePrevStage,
  isPrevVisible,
  thumbnail,
  backToHome,
  replyAnswer,
  setReplyAnswer,
  loading,
  showPopUp,
  setShowPopUp,
  title,
  textHeader,
  ModelType,
  isModel,
  brandName,
  isQuizJourney,
}) => {
  //console.log("journeyData", journeyData);
  //const { projectData, APIRequestId, setUserName } =
  //useContext(ProjectDataContext);
  //console.log("Stage ProjectDataContext", projectData);
  //const [stageMain, setStageMain] = useState<string[][]>([]);
  //console.log("stageData", stageData);

  const [stageMain, setStageMain] = useState([]);
  const [allStageDataPlayer, setAllStageDataPlayer] = useState(
    allStageData.data
  );
  useEffect(() => {
    setStageMain(stageData);
  }, [stageData]);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [isVideoEnded, setIsVideoEnded] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isAutoPlay, setIsAutoPlay] = useState<boolean>(false);
  const [mute, setMute] = useState<boolean>(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [isConfirmPopUp, setIsConfirmPopUp] = useState<boolean>(false);
  const [speedValue, setSpeedValue] = useState<string | number>("1.0");
  const [LocaltagData, setLocaltagData] = useState(null);
  const [quizData, setQuizData] = useState<
    Array<{
      stage?: string;
      scored?: number;
      actualScore?: number;
      answer: string;
    }>
  >();

  const togglePlay = useCallback(() => {
    if (videoRef?.current) {
      if (isPlaying) {
        videoRef.current?.pause();
      } else {
        videoRef.current?.play();
      }
      setIsVideoEnded(false);
      setIsPlaying(!isPlaying);
    }
  }, [isPlaying]);

  const onSpeedChange = useCallback((value: string | number) => {
    setSpeedValue(value);
    if (videoRef?.current) {
      videoRef.current.playbackRate = parseFloat(value as string);
    }
  }, []);

  const toggleMute = useCallback(
    (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event?.stopPropagation();
      setMute((prev) => !prev);
    },
    []
  );

  const handleTimeUpdate = () => {
    if (videoRef?.current) {
      setProgress(
        (videoRef.current.currentTime / videoRef.current.duration) * 100
      );
    }
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (videoRef?.current) {
      const time =
        (parseFloat(e.target.value) / 100) * videoRef.current.duration;
      videoRef.current.currentTime = time;
    }
  };

  const onVideoEnd = () => {
    setIsPlaying(false);
    setIsVideoEnded(true);
  };

  //FROMCUSTOM
  const [onAnsQuizAnswerArray, setOnAnsQuizAnswerArray] = useState<any[]>([]);

  const onAnswerChange = useCallback(
    (answer: string) => {
      //console.log("answer", answer);
      setReplyAnswer(answer);

      if (answer?.trim()) {
        setError("");
      }
    },
    [setReplyAnswer]
  );

  const onSubmit = useCallback(() => {
    setIsVideoEnded(true);
    setIsPlaying(false);
    if (replyAnswer?.trim()) {
      if (journeyData?.ReplyType === "quizButton") {
        const scored = quizData?.reduce(
          (total, currentObject) => total + getNum(currentObject.scored),
          0
        );
        const actualScored = quizData?.reduce(
          (total, currentObject) => total + getNum(currentObject.actualScore),
          0
        );
        console.log({ quizData, stageData: journeyData?.stage });
        const tagData = {
          answer: quizData?.[quizData.length - 1].answer,
          totalScore: actualScored,
          scoreArchieved: scored,
          stage: journeyData?.stage,
          quiztype: "CareerPath",
          optionSelected: onAnsQuizAnswerArray,
          replyAnswer: replyAnswer,
          quizData: quizData,
        };
        handleNextStage(journeyData?.stage, tagData);
      } else {
        handleNextStage(journeyData?.stage);
      }
      setSpeedValue("1.0");
      setProgress(0);
      setReplyAnswer("");
      setError("");
    } else if (journeyData?.ReplyType === "voiceInput") {
      const savedAudioResp = localStorage.getItem("audioResp");
      console.log("savedAudioResp", savedAudioResp);
      const tagData = {
        stage: journeyData?.stage,
        replyAnswer: savedAudioResp,
      };

      console.log("Voice Input", journeyData?.stage);
      console.log("tagData", tagData);

      handleNextStage(journeyData?.stage, tagData);
      setReplyAnswer("");
    } else if (journeyData?.ReplyType === "videoInput") {
      // console.log("replyAnswer inside", replyAnswer);
      // console.log("replyAnswer videolink", videolink);

      const savedVideoResp = localStorage.getItem("videoResp");
      if (savedVideoResp) {
        const savedVideoRespJSON = JSON.parse(savedVideoResp);
        console.log("savedVideoRespJSON", savedVideoRespJSON);
      }

      console.log("savedAudioResp", savedVideoResp);
      const tagData = {
        stage: journeyData?.stage,
        replyAnswer: savedVideoResp,
      };

      console.log("Voice Input", journeyData?.stage);
      console.log("tagData", tagData);
      setReplyAnswer(JSON.stringify(tagData));
      handleNextStage(journeyData?.stage, tagData);
      //setReplyAnswer("")
    } else {
      setError("Invalid Input");
    }
  }, [
    replyAnswer,
    journeyData?.ReplyType,
    journeyData?.stage,
    setReplyAnswer,
    quizData,
    handleNextStage,
  ]);

  useEffect(() => {
    if (journeyData?.video) {
      setIsVideoEnded(false);
      setProgress(0);
    } else {
      setIsVideoEnded(true);
    }
  }, [journeyData?.video]);

  const onPrevClick = useCallback(() => {
    handlePrevStage(journeyData?.stage);
    setReplyAnswer("");
    setSpeedValue("1.0");
    setIsPlaying(false);
  }, [handlePrevStage, journeyData?.stage, setReplyAnswer]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      // Add an event listener for the 'canplaythrough' event
      videoElement.addEventListener("canplaythrough", () => {
        // Video is fully loaded
        setVideoLoaded(true);
      });
    }

    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      if (videoElement) {
        videoElement.removeEventListener("canplaythrough", () => {
          // Do nothing
        });
      }
    };
  }, [journeyData?.video]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoLoaded && videoElement && !showPopUp) {
      // Auto play the video when it's fully loaded
      setTimeout(() => {
        setIsAutoPlay(true);
        videoElement.play();
        setIsVideoEnded(false);
        setIsPlaying(true);
      }, 500);
    }
  }, [videoLoaded, journeyData?.video, showPopUp]);

  const hideVideoControls = useMemo(() => {
    return !(!!journeyData?.ReplyType && isVideoEnded);
  }, [journeyData?.ReplyType, isVideoEnded]);

  useEffect(() => {
    if (videoRef.current && !showPopUp) {
      setIsAutoPlay(true);
      videoRef.current.play();
      setIsVideoEnded(false);
      setIsPlaying(true);
    }
  }, [showPopUp]);

  // Need to be integrated
  const onCancel = useCallback(() => {
    setShowPopUp(false);
    if (videoRef.current) {
      setIsAutoPlay(true);
      videoRef.current.play();
      setIsVideoEnded(false);
      setIsPlaying(true);
    }
  }, [setShowPopUp]);

  const handleResetJourney = useCallback(
    (action: "yes" | "no") => {
      if (action === "yes") {
        window.location.reload();
        backToHome?.(action);
        videoRef.current?.pause();
        setIsVideoEnded(false);
        setIsPlaying(false);
        setProgress(0);
        if (videoRef.current?.currentTime) {
          videoRef.current.currentTime = 0;
        }
      } else {
        togglePlay();
      }
      setIsConfirmPopUp(false);
    },
    [backToHome, togglePlay]
  );

  const showConfirmPopUp = useCallback(
    (event?: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      event?.stopPropagation();
      setIsConfirmPopUp(true);
      videoRef.current?.pause();
    },
    []
  );

  const handleVideoToggle = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      togglePlay();
    },
    [togglePlay]
  );

  const handleProgressClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      event.stopPropagation();
    },
    []
  );

  return (
    <>
      {isConfirmPopUp && (
        <PopUp>
          <ConfirmPopUp onSubmit={handleResetJourney} />
        </PopUp>
      )}

      <div className={`video-player${className ? " " + className : ""}`}>
        <div className="video-container">
          {journeyData?.video && (
            <video
              onTimeUpdate={handleTimeUpdate}
              ref={videoRef}
              onEnded={onVideoEnd}
              key={journeyData.video}
              controls={false}
              playsInline
              autoPlay={isAutoPlay}
              muted={mute}
            >
              <source id="start" type="video/mp4" src={journeyData.video} />
              Your browser does not support the video.
            </video>
          )}
        </div>

        <div
          className={`video-upper-layer${!isPlaying ? " bg" : ""}`}
          onClick={handleVideoToggle}
        >
          <div
            className={`video-controls${journeyData?.video ? "" : " bg"}`}
            style={{
              backgroundImage: `${
                !isPlaying
                  ? journeyData?.video
                    ? "none"
                    : `url(${thumbnail})`
                  : "none"
              }`,
            }}
          >
            {stageMain &&
              stageMain.map(
                (item: { [key: string]: string[] }, index: number) => {
                  const keys = Object.keys(item);
                  // console.log("keys", keys);
                  return keys.map((key) => {
                    // Check if the key matches the stage from journeyData
                    if (
                      Array.isArray(item[key]) &&
                      item[key].includes(journeyData?.stage || "")
                    ) {
                      // console.log(`Contents of ${key}:`, item[key]);

                      let newArray: any[] = [];

                      item[key].forEach((element1, index1) => {
                        // console.log("element1", element1);
                        let matchFound = false;
                        allStageData.data.forEach(
                          (element2: any, index2: any) => {
                            //console.log("element2", element2.stage);
                            if (
                              element1 === element2.stage &&
                              element2.isSeen === true
                            ) {
                              newArray.push(element1);

                              matchFound = true;
                            }
                            //console.log("newArray", newArray);
                          }
                        );
                      });

                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          key={`${index}-${key}`} // Ensure unique key
                          className="pill-container"
                        >
                          {item[key].map((stage, stageIndex) => (
                            <div
                              className="pill-item"
                              key={`${index}-${key}-${stageIndex}`}
                            >
                              {" "}
                              {journeyData?.stage === stage ? (
                                <div className="progress-bar d-flex-center">
                                  <input
                                    type="range"
                                    className="status-range"
                                    min="0"
                                    max="100"
                                    step="0.1"
                                    value={progress}
                                  />
                                </div>
                              ) : (
                                <span
                                  style={{
                                    backgroundColor: newArray.includes(stage)
                                      ? "white"
                                      : "#8f8e8e",
                                  }}
                                  className="pill"
                                ></span>
                              )}
                            </div>
                          ))}
                        </div>
                      );
                    }
                    return null; // If key doesn't match, return null
                  });
                }
              )}

            <HomeIcon
              width={30}
              height={30}
              color="#fff"
              className="home-ic"
              onClick={showConfirmPopUp}
            />
            <div className="upper">
              {/* Commented for direct flow */}
              {isPrevVisible && !isQuizJourney && (
                <Prev
                  width={40}
                  height={40}
                  color="#fff"
                  onClick={onPrevClick}
                  className="prev-ic"
                />
              )}
            </div>
            {journeyData?.video && (
              <div
                className={`middle${isPlaying ? " hide-play" : ""}`}
                onClick={togglePlay}
              >
                <div className="rounded-shadow" onClick={togglePlay}>
                  {progress === 100 ? (
                    <ReloadIcon width={100} height={100} color="#fff" />
                  ) : (
                    <>
                      {isPlaying ? (
                        <Pause width={100} height={100} />
                      ) : (
                        <Play width={100} height={100} />
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="lower">
              {isVideoEnded && (
                <div className="reply-section">
                  <JourneyReplyType
                    onAudioEnd={onAudioEnd}
                    loading={loading}
                    replyAnswer={replyAnswer}
                    onAnswerChange={onAnswerChange}
                    onSubmit={onSubmit}
                    error={error}
                    setReplyAnswer={setReplyAnswer}
                    type="StandAlone"
                    quizData={quizData}
                    setQuizData={setQuizData}
                    journeyData={journeyData}
                  />
                </div>
              )}
              {journeyData?.video && (
                <>
                  {hideVideoControls && (
                    <div className="progress-bar d-flex-center">
                      <div className="volume-sec d-flex-center">
                        <div
                          className={`speaker-ic${mute ? " mute" : ""}`}
                          onClick={toggleMute}
                        >
                          <Unmute width={30} height={40} color="#fff" />
                        </div>
                      </div>
                      <input
                        type="range"
                        className="range"
                        min="0"
                        max="100"
                        step="0.1"
                        value={progress}
                        onClick={handleProgressClick}
                        onChange={handleSeek}
                      />
                      <Dropdown
                        value={speedValue}
                        onChange={onSpeedChange}
                        dropDownItems={[
                          "0.5",
                          "0.75",
                          "1.0",
                          "1.25",
                          "1.5",
                          "1.75",
                          "2.0",
                        ]}
                        className="speed-dropdown"
                      />
                    </div>
                  )}
                  <div className="navigation">
                    {journeyData.ReplyType === "video" && (
                      <VideoReply
                        label={""}
                        labelheader={""}
                        ModelType={""}
                        isModel={""}
                        onSubmit={onSubmit}
                        error={""}
                        onChange={onAnswerChange}
                        stage={journeyData?.stage}
                        connectorName={journeyData?.connectorName}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JourneyPlayer;
